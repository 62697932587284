<template>
  <v-container class="fill-height wena my-0" fluid>
    <v-row justify="center">
      <v-col
        cols="12"
        sm="8"
        md="4"
        lg="4"
        xl="4"
      >
        <v-system-bar
          color="primary2"
          dark
          class="pb-7"
        >
          <v-spacer></v-spacer>
          <v-menu offset-y>
            <template v-slot:activator="{ on }">
              <v-btn text color v-on="on">
                <flag :iso="currentFlag" v-bind:squared="false" />
                <v-icon right color="primary">mdi-menu-down</v-icon>
              </v-btn>
            </template>
            <v-list dense>
              <v-list-item
                v-for="(entry, index) in languagess"
                :key="`${index}010`"
                @click="changeLocale(entry)"
              >
                <v-list-item-title>
                  <flag :iso="entry.flag" v-bind:squared="false" />
                  &nbsp;{{ entry.title }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-system-bar>
        <v-card class="pb-10">
          <v-row justify="center">
            <v-col
              cols="4"
              md="5"
            >
              <v-responsive class="pt-3 px-12 mx-2 pb-3">
                <v-img
                  width="80px"
                  src="/login.svg"
                ></v-img> 
              </v-responsive>
            </v-col>
          </v-row>
          <v-card-title v-if="!isPassword" class="subtitle-1 text-center justify-center px-12 pb-0 title-card">
            {{ texts.title}}
          </v-card-title>
        <v-card-title v-else class="subtitle-1 text-center justify-center px-12 pb-0">
            <p >{{ $t('enter_new_password') }}</p>
          </v-card-title>
          <v-card-title v-if="!isInitiate && !isPassword" class="subtitle-2 text-center justify-center pb-5 px-12">
            {{ texts.subtitle}}
          </v-card-title>
          <v-card-text class="px-10">
            <v-row v-if="isInitiate && !isPassword" class="justify-center text-center">
              <v-col cols="6">
                <v-radio-group v-model="fieldSelected">
                  <v-radio value="email">
                    <template v-slot:label>
                      <div>
                        <div>Send code via email</div>
                        <div v-if="isEmail" class="subtitle-2">{{ userAccount.email }}</div>
                        <div v-else class="subtitle-2">{{userAccount.email[0]}}***@*********</div>
                      </div>
                    </template>
                  </v-radio>
                  <!-- <v-radio value="number">
                    <template v-slot:label>
                      <div>
                        <div v-if="!isEmail">+{{ userAccount.telephone }}</div>
                        <div v-else class="subtitle-2">{{hidedMobileNumber()}}</div>
                      </div>
                    </template>
                  </v-radio> -->
                </v-radio-group>
              </v-col>
              <v-col cols="6">
                <v-row justify="center" class="text-center">
                  <v-col
                    cols="12"
                    md="12"
                    class="mt-3"
                  >
                    <v-responsive class="">
                      <v-avatar>
                        <img
                          :src="
                              userAccount.photo
                                ? `${apiUrl}/images/user/${userAccount.photo}`
                                : `/avatar.png`
                            "
                          alt="John"
                        >
                      </v-avatar>
                    </v-responsive>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>{{ userAccount.name }}</v-list-item-title>
                        <v-list-item-subtitle>Utilizador do AMER</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row v-else-if="!isInitiate && !isPassword" class="justify-center text-center">
              <v-text-field
                v-model="verificationCodeEnter"
                :placeholder="`${$t('enter_code')}`"
                outlined
                type="text"
                dense
                required
              ></v-text-field>
            </v-row>
            <v-row v-else-if="!isInitiate && isPassword">
              <v-col cols="12" md="12">
                <v-text-field
                  v-model="password"
                  :label="$t('new_password')"
                  outlined
                  type="password"
                  dense
                  required
                  hide-details
                  :rules="requiredRules"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="12">
                <v-text-field
                  v-model="repeatPassword"
                  :label="$t('confirm_password')"
                  outlined
                  type="password"
                  dense
                  required
                  hide-details
                  :rules="repeatPasswordRules"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row v-if="!isInitiate" class="d-flex flex-row justify-space-between align-center mt-5 px-3">
              <div v-if="!isInitiate"><a @click.prevent="cancelRecover()">{{$t('Do_not_get_code')}}</a></div>
              <div>
                <v-btn
                  v-show="!isInitiate"
                  class="mx-2"
                  type="reset"
                  @click.prevent="cancelRecover()"
                >{{$t('cancel')}}</v-btn>
                <v-btn
                  :loading="isLoading"
                  :disabled="isLoading"
                  color="primary"
                  @click.prevent="submit"
                >{{$t('Continue')}}</v-btn>
              </div>
            </v-row>
            <div v-else class="mt-5">
              <v-btn
                :loading="isLoading"
                :disabled="isLoading"
                color="primary"
                block
                @click.prevent="submit"
              >{{$t('Continue')}}</v-btn>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { API_URL } from "@/api";
import { 
  SEND_VERIFY_CODE_EMAIL_MUTATION, 
  SEND_VERIFY_CODE_SMS_MUTATION,
  UPDATE_USER_PASSWORD_MUTATION,
} from './../graphql/mutation.gql';
import { GC_AUTH_TOKEN, GC_USER_ID } from '@/constants/settings';
import { formatError } from '@/utils';
import { mapActions } from 'vuex';
import accessLevelMixins from "@/mixins/access-level";
export default {
  name: 'RecoverPassword',
  mixins: [accessLevelMixins],
  components: {
  },
  data: () => ({
    apiUrl: API_URL,
    currentFlag: "",
    isInitiate: true,
    isLoading: false,
    isPassword: false,
    fieldSelected: '',
    userAccount: null,
    verifyCode: undefined,
    verificationCodeEnter: undefined,
    password: "",
    repeatPassword: "",
  }),

  created () {
    this.noInternet = window.navigator.onLine ? false : true
    this.currentFlag = localStorage.getItem("flag") ? localStorage.getItem("flag") : 'pt';
    this.fieldSelected = this.$route.query.typeEnter.type;
    this.userAccount = this.$route.query.userAccount;
    console.log("type: ", this.$route.query.userAccount)
    if (this.noInternet) {
      this.error = 'No Internet Connection'
      this.showError = true
    }
  },

  computed: {
    isEmail () {
      return this.$route.query.typeEnter.type === 'email'
        ? true : false;
    },
    languagess: function() {
      return [
        { flag: "gb", language: "en", title: "English" },
        // { flag: "fr", language: "fr", title: "Français" },
        { flag: "pt", language: "pt", title: "Português" },
      ];
    },
    repeatPasswordRules() {
      return [(v) => v === this.password || `${this.$t('diff_password')}`];
    },
    requiredRules() {
      return [(v) => !!v || `${this.$t('email_required')}!`];
    },
    texts () {
      const field = this.fieldSelected === 'email' ? 'email' : this.$t('mobile_phone');
      let message = '';

      if (this.fieldSelected === 'email' && this.isEmail) {
        message = this.userAccount.email;
      } else if (this.fieldSelected === 'email' && !this.isEmail) {
        message = `${this.userAccount.email[0]}***@*********`;
      } else if (this.fieldSelected !== 'email' && !this.isEmail) {
        message = '+'+this.userAccount.telephone;
      } else {
        message = this.hidedMobileNumber();
      }

      return this.isInitiate
        ? { title: `${this.$t('how_want_get_code')}`, subtitle: '', button: this.$t('btn_login') }
        : { title: `${this.$t('check_phone_message_code', {field: field})}`, subtitle: `${this.$t('we_set_your_code_to', {message: message})}`, button: `${this.$t('submit_test')}` }
    },
  },

  methods: {
    ...mapActions({
      setUserState: 'auth/setUser'
    }),
    cancelRecover () {
      this.isInitiate = true;
      if (this.isPassword) {
        this.$router.push('login')
      }
      this.isPassword = false;
    },
    changeLocale(locale) {
      this.$i18n.locale = locale.language;
      this.currentFlag = locale.flag;

      localStorage.setItem("lang", locale.language);
      localStorage.setItem("flag", locale.flag);
    },
    hidedMobileNumber () {
      let number = '+';
      let phone = this.userAccount.telephone;
      let lengthNumber = this.userAccount.telephone.length;
      for (let index = 0; index < lengthNumber - 2; index++) {
        number = number+'*';
      }
      return number+phone[lengthNumber - 2]+''+phone[lengthNumber - 1]
    },
    saveUserData (id, token) {
      localStorage.setItem(GC_USER_ID, id)
      localStorage.setItem(GC_AUTH_TOKEN, token)
      this.$root.$data.userId = localStorage.getItem(GC_USER_ID)
    },
    setRoute (user) {
      if (this.accessLevel(user, "dashboard")) {
        return 'dashboard'
      } 
      return '/dashboard/entity/user/profile'
    },
    async submit () {
      this.isLoading = true;
      if (this.isInitiate) {
        try {
          if (this.fieldSelected === 'email') {
            const { data } = await this.$apollo.mutate({
              mutation: SEND_VERIFY_CODE_EMAIL_MUTATION,
              variables: {
                email: this.userAccount.email
              }
            });
            this.verifyCode = data.sendVerifyCodeEmail;
            this.isInitiate = false;
          } else {
            const { data } = await this.$apollo.mutate({
              mutation: SEND_VERIFY_CODE_SMS_MUTATION,
              variables: {
                telephone: this.userAccount.telephone
              }
            });
            this.verifyCode = data.sendVerifyCodeSMS;
            this.isInitiate = false;
          }
        } catch (error) {
          this.error = formatError(error.message);
          this.showError = true;
        } finally {
          this.isLoading = false;
        }
      } else if (this.isPassword) {
        try {
          const { data } = await this.$apollo.mutate({
            mutation: UPDATE_USER_PASSWORD_MUTATION,
            variables: {
              id: this.userAccount.id,
              password: this.password
            }
          });

          console.log("data.updateUserPassword: ", data.updateUserPassword.user.type)
          const entityRedirect = this.setRoute(data.updateUserPassword.user);
          console.log("entityRedirect: ", entityRedirect);

          const id = data.updateUserPassword.user.id
          const token = data.updateUserPassword.token
          this.saveUserData(id, token)
          this.setUserState(data.updateUserPassword.user)


          switch (data.updateUserPassword.user.type) {
            case 'candidate': this.$router.push('/candidate'); break;
            case 'entity': this.$router.push(entityRedirect); break;
            case 'partner': this.$router.push('/partner'); break;
            case 'root': this.$router.push('/root'); break;
          }
        } catch (error) {
          this.error = formatError(error.message);
          this.showError = true;
        } finally {
          this.isLoading = false;
        }
      } else {
        if (this.verifyCode == this.verificationCodeEnter) {
          this.isLoading = false;
          this.isPassword = true;
        } else {
          this.error = 'Invalid code';
          this.showError = true
        }
      }
    }
  }
}
</script>

<style scoped>
.wena {
  background: #F5FAFF;
}
.title-card {
  white-space: pre-wrap;
}
</style>